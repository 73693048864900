import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import ModularBlocks from '../../components/ModularBlocks';
import ContactForm from '../../components/ContactForm';
import CompanyReviews from '../../components/CompanyReviews';

const SectorPageTemplate = ({
  data: {
    datoCmsSector: { seoMetaTags, title, modularBlocks },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <PageHeader
        heading={title}
        breadcrumbs={[
          { slug: 'plants-for-business', text: 'Plants for Business' },
        ]}
      />
      <ModularBlocks items={modularBlocks} />
    </main>
    <ContactForm />
    <CompanyReviews />
  </Layout>
);

export const SectorPageTemplateQuery = graphql`
  query SectorPageTemplateQuery($id: String!) {
    datoCmsSector(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...HighlightedTextModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
    }
  }
`;

export default SectorPageTemplate;
